import {GoogleLogin} from "./googleLogin";
import {BackgroundMusic} from "./backgroundMusic";
import {AmbianceSounds} from "./ambianceSounds";
import {SoundBoard} from "./soundboard";

document.addEventListener('DOMContentLoaded', () => {
    console.log("🟢 uiHandler.js chargé et prêt");

    document.addEventListener('userLoggedIn', (event) => {
        console.log("🎉 userLoggedIn capté avec succès:", event.detail);

        const loginButton = document.getElementById('loginId');
        const loginModal = document.getElementById('login-modal');

        if (!loginButton) {
            console.error("⚠️ Bouton de connexion non trouvé");
            return;
        }

        loginButton.textContent = 'Logout';
        loginButton.onclick = () => GoogleLogin.handleLogout();
        if (loginModal) {
            loginModal.classList.add('hidden');
            loginModal.classList.remove('flex');
        }

        BackgroundMusic.preloadBackgroundSounds();
        AmbianceSounds.loadAmbianceButtons();
        SoundBoard.loadSoundboardButtons();
    });

    document.addEventListener('userLoggedOut', () => {
        console.log("🚪 userLoggedOut capté");

        const loginButton = document.getElementById('loginId');
        const loginModal = document.getElementById('login-modal');

        if (!loginButton) {
            console.error("⚠️ Bouton de connexion non trouvé");
            return;
        }

        loginButton.textContent = 'Login';
        loginButton.onclick = () => GoogleLogin.init();
        if (loginModal) {
            loginModal.classList.remove('hidden');
            loginModal.classList.add('flex');
        }

        BackgroundMusic.preloadBackgroundSounds();
        AmbianceSounds.loadAmbianceButtons();
        SoundBoard.loadSoundboardButtons();
    });
});